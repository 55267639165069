const storeItem = (key,value) => {
    localStorage.setItem(key,value);
}

const getItem = (key) => {
    const token = localStorage.getItem(key);
    if(token){
        return token;
    }
    else{
        return null;
    }
}

const storeToken = (value, time) => {
    let data = {
        value,
        expiry : Date.now() + time*1000,
    }
    localStorage.setItem('token', JSON.stringify(data));
}

const getToken = () => {
    let data = localStorage.getItem('token');
    
    if (data === null) {
        return null;
    }
    
    data = JSON.parse(data);
    
    if (data.expiry <= Date.now()) {
        localStorage.removeItem('token');
        return null;
    } else {
        return data.value;
    }
}


const removeItem = (key) => {
    localStorage.removeItem(key);
}



export {storeItem, getItem, storeToken, getToken, removeItem}